@import 'styles/theme';

.ArrowLeftIcon {
  transform: rotateZ(180deg);
}

.ArrowBottomIcon {
  transform: rotateZ(90deg);
}

.Search {
  width: 14px;
}

.CloseIcon {
  &.whiteTheme {
    path {
      fill: theme($colors, 'white');
      stroke: theme($colors, 'white');
    }
  }
}

.whiteTheme {
  path {
    &[fill] {
      fill: theme($colors, 'white');
    }

    &[stroke] {
      stroke: theme($colors, 'white');
    }
  }
}
