@import 'styles/theme';

.Footer {
  display: flex;
  flex-direction: column;
  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 20);

  &.isForbiddenCountry {
    .linksContainer {
      @include from('md') {
        grid-column: 2 / -2;
        margin-top: 0;
      }
    }
  }

  .topFooter {
    padding-top: theme($spacings, 20);
    padding-bottom: theme($spacings, 20);

    &.borderBottom {
      border-bottom: 1px solid theme($colors, 'dove-gray');
    }

    .topContent {
      grid-column: 2 / -2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .breadcrumb {
      word-break: break-word;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .langSwitcher {
      flex: 65%;
    }

    + .supFooter {
      padding-top: theme($spacings, 25);

      @include from('md') {
        margin-top: 0;
      }
    }

    + .mainFooter {
      margin-top: theme($spacings, 40);

      @include from('md') {
        margin-top: theme($spacings, 70);
      }
    }
  }

  .supFooter {
    border-top: 1px solid theme($colors, 'concrete');
    border-bottom: 1px solid theme($colors, 'concrete');
    padding-bottom: theme($spacings, 25);

    @include from('md') {
      padding-bottom: theme($spacings, 30);
    }

    .supFooterContent {
      grid-column: 1 / -1;
      overflow: hidden;
    }

    .supFooterItem {
      box-sizing: border-box;

      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 6,
          'mobile': 24,
        )
      );

      @include from('md') {
        @include applyColumnWidth('padding-right', 0.5, $desktop);
        @include applyColumnWidth('padding-right', 0.5, $desktop);

        &:first-child {
          @include applyColumnWidth('padding-left', 1, $desktop);
        }

        &:last-child {
          @include applyColumnWidth('padding-right', 1, $desktop);
        }
      }
    }

    + .mainFooter {
      margin-top: theme($spacings, 40);

      @include from('md') {
        margin-top: theme($spacings, 70);
      }
    }
  }

  .newsletter {
    grid-column: 2 / -2;

    @include from('md') {
      grid-column: 2 / 7;
    }
  }

  .linksContainer {
    grid-column: 2 / -2;

    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: theme($spacings, 25);

    @include applyColumns(
      (
        'propertyName': 'gap',
        'desktop': 1,
        'mobile': 0,
      )
    );

    @include from('md') {
      grid-column: 9 / -2;
      margin-top: 0;
    }

    .links {
      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 3,
          'mobile': 22,
        )
      );
    }
  }

  .reassurance {
    margin-top: theme($spacings, 25);
    grid-column: 2/-2;

    @include from('md') {
      text-align: center;
      margin-top: theme($spacings, 70);
    }
  }

  .marker {
    height: 1px;
    width: 100%;
    visibility: hidden;
  }

  @include from('md') {
    margin-top: theme($spacings, 100);
    margin-bottom: theme($spacings, 40);

    // Special order on desktop
    .supFooter {
      margin-top: 0;
      padding-top: theme($spacings, 30);
    }
  }

  @include switchDesktopMobileClasses('md', flex);
}
