@import 'styles/theme';

.Checkbox {
  .content {
    align-items: center;
  }

  &.vertical-align-baseline {
    .content {
      align-items: baseline;

      .checkmark {
        top: 2px;
      }
    }
  }

  .error {
    border-color: currentColor;
    color: theme($colors, 'pomegranate');
  }

  .checkmarkActive {
    &.error {
      background-color: theme($colors, 'pomegranate');
    }
  }

  .checkIcon {
    width: 75%;
  }

  &.theme-dove-gray {
    .checkmark {
      border-color: theme($colors, 'alto');
    }

    .checkmarkActive {
      background-color: theme($colors, 'alto');
    }
  }

  &.theme-alto {
    .checkmark {
      border-color: theme($colors, 'white');
    }

    .checkmarkActive {
      background-color: theme($colors, 'white');

      path {
        fill: theme($colors, 'black');
      }
    }
  }

  &.theme-white {
    .checkmark {
      border-color: theme($colors, 'white');

      .checkmarkActive {
        background-color: theme($colors, 'mine-shaft');
      }
    }

    .label {
      a {
        line-height: 1;
      }
    }
  }
}
