@import 'styles/theme';

.SupFooter {
  @include switchDesktopMobileClasses('md');
  overflow-x: hidden;
  max-width: 100%;

  @include from('md') {
    &:not(.enableDrag) {
      display: flex;
      justify-content: center;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .name,
      .label {
        @include fontSmoothed();
      }

      .image {
        width: 20px;

        + .name {
          margin-top: theme($spacings, 15);
        }

        @include from('md') {
          width: 30px;

          + .name {
            margin-top: theme($spacings, 20);
          }
        }
      }
    }
  }

  .sliderNavigation {
    margin-top: theme($spacings, 10);
    text-align: center;
    width: 100%;
  }
}
