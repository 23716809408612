@import 'styles/theme';

.OrdersGrid {
  position: relative;

  .spinner {
    width: theme($spacings, 15);
  }

  .header {
    width: calc(100% - 2 * #{theme($spacings, 30)});
    margin: theme($spacings, 45) theme($spacings, 30) theme($spacings, 20);
    flex-direction: column;
    display: none;

    @include from('md') {
      width: calc(100% - 2 * #{theme($spacings, 25)});
      margin: theme($spacings, 45) theme($spacings, 25) theme($spacings, 20);
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      p {
        flex: 2;
        text-align: left;
        white-space: nowrap;

        &:first-child {
          flex: 3;
        }

        &:last-child {
          text-align: right;
        }

        &:not(:last-child) {
          margin-right: theme($spacings, 10);

          span {
            &:first-child {
              display: none;
            }
          }
        }

        + p {
          margin-top: 0;
        }
      }
    }
  }

  .container {
    border: solid 1px theme($colors, 'alto');

    + .container {
      margin-top: theme($spacings, 10);
    }
  }

  .noOrders {
    .noOrdersText {
      margin-top: theme($spacings, 30);
      text-align: center;
    }

    @include from('md') {
      .noOrdersText {
        text-align: left;
      }
    }
  }
}
