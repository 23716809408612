@import 'styles/theme';

.Navigation {
  @include switchDesktopMobileClasses('lg', grid);
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 40px;

  &.isLoaded {
    padding-top: var(--banner-height);
  }

  &.isFixed {
    position: fixed;
    padding-top: 0;
  }
}

.marker {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 1px;
}

.NavigationIsFixedRef {
  top: calc(var(--banner-height) - 2px);
}

.NavigationScrollRef {
  top: 10px;
}
