@import 'styles/theme';

.PanelBody {
  @include from('md') {
    border-top: solid 1px theme($colors, 'alto');
  }

  .line {
    display: flex;
    flex-direction: column;
    padding: theme($spacings, 20) theme($spacings, 15);

    + .line {
      border-top: solid 1px theme($colors, 'alto');
    }

    &.isMixed {
      border-top-width: 2px;
    }

    @include from('md') {
      padding: theme($spacings, 15) theme($spacings, 25);
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
