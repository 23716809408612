@import 'styles/theme';

.paddings {
  box-sizing: border-box;
  padding: theme($spacings, 10) theme($spacings, 14);

  @include from('md') {
    padding: theme($spacings, 12) theme($spacings, 15);
  }
}

.DownshiftSelect {
  width: 100%;
  position: relative;

  &.isOpen {
    .icon {
      transform: rotate(180deg);
    }

    .options {
      &.overflow {
        height: vh(40);
        max-height: 300px;
        overflow: auto;

        @include from('md') {
          height: vh(30);
        }

        &::-webkit-scrollbar {
          height: 3px;
          width: 3px;
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(51, 51, 51, 0.1);
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .button {
      background-color: theme($colors, 'gallery');
      color: theme($colors, 'quick-silver');
      border: 1px solid theme($colors, 'quick-silver');

      path {
        fill: theme($colors, 'quick-silver');
      }
    }
  }

  .icon {
    line-height: 0;
    width: 8px;
    transform: rotate(0deg);
    transform-origin: center center;

    path {
      fill: #676767;
    }
  }

  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    @extend .paddings;

    .checkIcon {
      height: 8px;
      margin-right: theme($spacings, 8);

      path {
        fill: #676767;
      }
    }

    span {
      margin-right: theme($spacings, 15);
    }
  }

  .options {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    background-color: theme($colors, 'white');
    border-left: 1px solid theme($colors, 'alto');
    border-right: 1px solid theme($colors, 'alto');
    box-sizing: border-box;
    z-index: 102;
    max-height: 350px;
    overflow-y: scroll;

    &.isOpeningUp {
      top: 0;
      bottom: auto;
      transform: translateY(-100%);

      &.isOpen {
        border-top: 1px solid theme($colors, 'alto');
        border-bottom: none;
      }
    }

    &.isOpen {
      border-bottom: 1px solid theme($colors, 'alto');
    }

    .option {
      position: relative;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      background-color: theme($colors, 'white');
      @extend .paddings;
      border-bottom: 1px solid theme($colors, 'alto');

      span {
        color: theme($colors, 'dove-gray');
        transition: ease 0.15s all;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        span {
          color: theme($colors, 'black');
          opacity: 1;
        }
      }
    }
  }

  .button {
    border: 1px solid theme($colors, 'alto');
  }
}
