@import 'styles/theme';

$dotSize: 5px;
.DotCheckbox {
  cursor: pointer;
  width: 100%;

  .error {
    border-color: currentColor;
    color: theme($colors, 'pomegranate');
  }

  .input:checked {
    & + .content {
      opacity: 1;

      .checkmark {
        opacity: 1;
        transform: translate3d(0, -50%, 0);
      }

      .label {
        transform: translate3d(10px, 0, 0);
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    width: $dotSize;
    height: $dotSize;
    transform: translate3d(-10px, -50%, 0);
    border-radius: 50%;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.25s linear,
      transform 0.3s theme($easings, 'ease-in-out');
    background: theme($colors, 'black');
  }

  .label {
    display: inline-block;
    transform: translate3d(0, 0, 0);
    margin: 0;
    transition: transform 0.3s theme($easings, 'ease-in-out');
  }

  .content {
    pointer-events: none;
    opacity: 0.7;
    transition: opacity 0.25s linear;
  }
}
