@use 'sass:math';

@import './mixins';
/*
  COLORS
*/
$colors: (
  'black': #000000,
  'concrete': #f2f2f2,
  'mine-shaft': #2d2d2d,
  'dove-gray': #707070,
  'quick-silver': #a0a0a0,
  'alto': #d2d2d2,
  'gallery': #f0f0f0,
  'alabaster': #fafafa,
  'white': #ffffff,
  'pomegranate': #f13535,
);

/*
  GRID
*/
$mobile: (
  'columns': 24,
  'gap': 0vw,
  'gutter': 0vw,
);

$desktop: (
  'columns': 24,
  'gap': 0vw,
  'gutter': 0vw,
);

$max: (
  'width-design': 1440px,
  'width': 1940px,
);

/*
  FONTS
*/

$fontFamilies: (
  Haffer: 'Haffer',
);

$fontSizes: (
  10: 10px,
  11: 11px,
  12: 12px,
  13: 13px,
  14: 14px,
  15: 15px,
  16: 16px,
  18: 18px,
  24: 24px,
  25: 25px,
  28: 28px,
  30: 30px,
  35: 35px,
  36: 36px,
  45: 45px,
  48: 48px,
  60: 60px,
  75: 75px,
  80: 80px,
);

$fluidFontSizes: (
  25: 25px,
  30: 30px,
  32: 32px,
  35: 35px,
  36: 36px,
  40: 40px,
  45: 45px,
  48: 48px,
  50: 50px,
  55: 55px,
  60: 60px,
  70: 70px,
  80: 80px,
  100: 100px,
  110: 110px,
  140: 140px,
  150: 150px,
  160: 160px,
  250: 250px,
  330: 330px,
);

$fontWeights: (
  'regular': 400,
  'medium': 500,
  'semibold': 600,
);

// MARGIN AND PADDING
$spacings: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  10: 10px,
  12: 12px,
  13: 13px,
  14: 14px,
  15: 15px,
  16: 16px,
  17: 17px,
  18: 18px,
  20: 20px,
  22: 22px,
  24: 24px,
  25: 25px,
  30: 30px,
  32: 32px,
  35: 35px,
  38: 38px,
  40: 40px,
  45: 45px,
  48: 48px,
  50: 50px,
  55: 55px,
  60: 60px,
  63: 63px,
  65: 65px,
  69: 69px,
  70: 70px,
  75: 75px,
  80: 80px,
  85: 85px,
  96: 96px,
  100: 100px,
  105: 105px,
  106: 106px,
  120: 120px,
  135: 135px,
  150: 150px,
  160: 160px,
  175: 175px,
  180: 180px,
  200: 200px,
  250: 250px,
  335: 335px,
);

/*
  SCREENS
*/
$screens: (
  xxxs: 375px,
  xxs: 480px,
  xs: 576px,
  sm: 640px,
  md: 768px,
  l: 930px,
  lg: 1024px,
  xl: 1280px,
  xxl: map-get($max, 'width-design'),
  'max-grid-width': map-get($max, 'width'),
);

/*
  RATIO
*/
$ratio: (
  '2/3': math.div(2, 3),
);

@function getBannerReassuranceHeight($height: 0px) {
  @return calc(#{$height} + var(--banner-height) * var(--banner-displayed));
}

@function getGlobalBannerHeight($height) {
  @return calc(#{$height} + var(--banner-height) * var(--banner-displayed));
}

$navHeight: (
  'mobile': 60px,
  'mobileWithBanner': getGlobalBannerHeight(60px),
  'desktop': 72px,
  'desktopWithBanner': getGlobalBannerHeight(72px),
  'banner': 40px,
);

$easings: (
  'ease-in-out': cubic-bezier(0.56, 0.21, 0.48, 0.85),
);

@mixin customScrollBarTheme() {
  scrollbar-color: theme($colors, 'dove-gray') theme($colors, 'alto');
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background-color: theme($colors, 'alto');
  }

  &::-webkit-scrollbar-thumb {
    background-color: theme($colors, 'dove-gray');
  }
}
