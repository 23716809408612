@import 'styles/theme';

.LangSwitcher {
  position: relative;
  justify-content: flex-end;
  padding-right: theme($spacings, 15);

  .icon {
    position: absolute;
    line-height: 0;

    width: 9px;
    height: 6px;
    transform: translateY(-50%);
    transform-origin: center center;
    top: 50%;
    right: -#{theme($spacings, 15)};
    pointer-events: none;
  }
}
