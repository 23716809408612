@import 'styles/theme';

$dotSize: 6px;
$dotSmallSize: 3px;

.SliderNavigation {
  .item {
    cursor: pointer;

    + .item {
      margin-left: theme($spacings, 10);

      &.dotSmall {
        margin-left: theme($spacings, 3);
      }
    }
  }

  .dot {
    display: inline-block;
    width: $dotSize;
    height: $dotSize;
    border-radius: 50%;
    border: 1px solid theme($colors, 'black');

    &.active {
      background-color: theme($colors, 'black');
    }
  }

  .dotSmall {
    display: inline-block;
    width: $dotSmallSize;
    height: $dotSmallSize;
    border-radius: 50%;
    background-color: theme($colors, 'alto');
    transition: background-color 0.3s theme($easings, 'ease-in-out');

    &.active {
      background-color: theme($colors, 'black');
    }
  }
}
