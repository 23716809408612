@import 'styles/theme';

.NavigationLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 20px;

  @include from('lg') {
    width: 234px;
  }

  path {
    transition: fill 0.2s linear;
  }

  // Themes
  &.blackTheme {
    path {
      fill: theme($colors, 'black');
    }
  }

  &.whiteTheme {
    path {
      fill: theme($colors, 'white');
    }
  }
}
