@import 'styles/theme';

.Slice404 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: theme($spacings, 135);

  @include from('md') {
    margin-top: theme($spacings, 250);
  }

  .texts {
    z-index: 1;
    position: relative;
    grid-column: 4 / -4;
    text-align: center;
  }

  .text {
    margin-top: theme($spacings, 15);

    @include from('md') {
      margin-top: theme($spacings, 25);
    }
  }

  .cta {
    margin-top: theme($spacings, 40);

    @include from('md') {
      margin-top: theme($spacings, 30);
    }
  }
}
