@import 'styles/theme';

.PanelManager {
  // Because we need to display country banner as panel we need it to be higher than cookie consent banner or we can't use country switcher because of clickOutside Listner
  z-index: 2147483647;
  position: fixed;
  top: 0;
  right: 0;
  width: vw(100);
  height: vh(100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;

  &.enterFromTop {
    z-index: 9;
  }

  @include from('lg') {
    top: 0;
    height: 100%;
    width: 100%;
  }

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &.enterFromLeft {
      align-items: flex-start;
    }

    &.enterFromTop {
      align-items: flex-start;
      flex-direction: row;
      z-index: 9;

      .content {
        height: auto;

        @include from('lg') {
          width: 100%;
        }
      }
    }
  }

  .background {
    display: none;
    pointer-events: auto;
    opacity: 0;

    @include from('lg') {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .content {
    pointer-events: auto;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: theme($colors, 'white');
    overflow: hidden;

    @include from('lg') {
      min-width: 550px;
      @include applyColumnWidth('width', 10, $desktop);
    }
  }

  &.context {
    .content {
      @include from('lg') {
        min-width: unset;
        @include applyColumnWidth('width', 9, $desktop);
      }
    }
  }

  .children {
    height: 100%;
    overflow-y: auto;
    @include customScrollBarTheme();
  }
}
