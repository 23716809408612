@import 'styles/theme';

#onetrust-consent-sdk {
  font-family: theme($fontFamilies, 'Haffer') !important;
  color: theme($colors, 'black') !important;
  box-shadow: none !important;

  .ot-pc-logo {
    display: none !important;
  }

  .ot-switch {
    width: 42px !important;
    height: 24px !important;

    .ot-switch-nob {
      background: transparent !important;
      border-radius: 42px !important;
      border: 1px solid theme($colors, 'dove-gray') !important;

      &:before {
        width: 20px !important;
        height: 20px !important;
        top: 1px !important;
        left: 1px !important;
        background-color: theme($colors, 'dove-gray') !important;
      }
    }
  }

  input:checked + .ot-switch .ot-switch-nob {
    border-color: theme($colors, 'black') !important;

    &:before {
      background-color: theme($colors, 'black') !important;
      transform: translateX(17px) !important;
    }
  }

  h2#ot-pc-title {
    margin: 0 !important;
  }

  h2,
  h3 {
    font-family: theme($fontFamilies, 'Haffer') !important;
    font-weight: theme($fontWeights, 'regular') !important;
    text-transform: uppercase !important;
    color: theme($colors, 'black') !important;
  }

  #onetrust-pc-sdk {
    border-radius: 0px !important;
    border: 1px solid theme($colors, 'black');

    .category-menu-switch-handler {
      outline-offset: 0px !important;
    }

    .ot-pc-header,
    .ot-title-cntr {
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      box-sizing: border-box !important;
    }

    .ot-close-cntr {
      float: unset !important;
    }

    .ot-tgl {
      input:focus {
        & + .ot-switch {
          .ot-switch-nob {
            &:before {
              outline: none !important;
            }
          }
        }
      }
    }
  }

  #ot-sdk-container {
    padding: 0px !important;
  }

  #onetrust-banner-sdk {
    box-sizing: border-box !important;
    padding: theme($spacings, 30) getGridColumnWidth(1, $desktop) !important;

    #onetrust-policy {
      padding: 0 !important;
      margin: 0 !important;
    }
    button {
      background-color: transparent !important;
    }

    &:focus {
      outline: none !important;
    }

    #onetrust-button-group {
      margin-top: 0;
      display: flex !important;
      flex-direction: column-reverse !important;
    }

    #onetrust-accept-btn-handler {
      margin: 0 !important;
    }

    #onetrust-group-container {
      width: 100% !important;
      margin-bottom: 25px !important;
    }

    @include from('md') {
      #onetrust-button-group-parent {
        width: 100% !important;
        left: 0 !important;
        top: 0 !important;
        padding: 0 !important;
        position: relative !important;
        transform: none;
      }
    }
  }

  p,
  strong {
    font-size: 12px !important;
    color: inherit !important;
    font-weight: theme($fontWeights, 'regular') !important;
  }

  strong {
    font-family: inherit !important;
    font-weight: theme($fontWeights, 'medium') !important;
  }

  button#accept-recommended-btn-handler,
  button#onetrust-reject-all-handler,
  button#onetrust-accept-btn-handler,
  button.save-preference-btn-handler {
    font-family: theme($fontFamilies, 'Haffer') !important;
    font-weight: theme($fontWeights, 'regular') !important;
    line-height: 1 !important;
    font-size: 12px !important;

    padding: theme($spacings, 12) theme($spacings, 20) !important;
    border-radius: 0px !important;
    text-transform: uppercase !important;
    box-sizing: border-box !important;
    transition: background-color 0.2s linear, color 0.2s linear,
      border-color 0.2s linear;

    border: 1px solid theme($colors, 'black') !important;
    background-color: theme($colors, 'black') !important;
    color: theme($colors, 'white') !important;

    &:hover {
      opacity: 1 !important;
    }

    &:focus {
      outline: none !important;
    }
  }

  button#onetrust-reject-all-handler {
    margin-top: theme($spacings, 5) !important;
  }

  button#onetrust-accept-btn-handler,
  button#onetrust-reject-all-handler {
    border: 1px solid theme($colors, 'black') !important;
    color: theme($colors, 'white') !important;
    background: theme($colors, 'black') !important;
  }

  button.cookie-setting-link,
  button.ot-link-btn {
    font-family: theme($fontFamilies, 'Haffer') !important;
    font-weight: theme($fontWeights, 'semibold') !important;
    color: theme($colors, 'black') !important;
    text-transform: uppercase;
    line-height: 1 !important;
    font-size: 12px !important;

    display: inline-block !important;
    padding: 0 !important;
    margin: 0px !important;
    margin-top: 9px !important;
    border: none !important;
    letter-spacing: auto !important;
    background-color: transparent !important;

    &:focus {
      outline: none !important;
    }

    &:hover {
      box-shadow: unset !important;
      opacity: 1 !important;
    }
  }

  .ot-fade-in,
  .ot-fade-out,
  #onetrust-banner-sdk {
    transition-duration: 0ms !important;
    animation-duration: 0ms !important;
  }

  button.category-host-list-handler {
    color: theme($colors, 'black') !important;
    text-decoration: underline !important;
  }
}
