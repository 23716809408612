@import 'styles/theme';

.InlineCta {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: color 0.2s linear, border-color 0.2s linear;

  &.hasLine {
    .contentContainer {
      overflow: hidden;
      padding-bottom: theme($spacings, 2);
    }
  }

  &.isStaticLine {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }

  &:disabled {
    opacity: 0.35;
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.loading {
    pointer-events: none;
  }

  .isLoading {
    opacity: 0;
  }

  .contentContainer {
    position: relative;
    display: block;
    word-break: break-word;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
  }

  .line {
    position: absolute;
    bottom: 0px;
    background-color: currentColor;
  }
}
