@import 'styles/theme';

.SelectNavigation {
  .select {
    @include applyColumnWidth('padding-left', 1, $mobile);
    @include applyColumnWidth('padding-right', 1, $mobile);
    border: none;
  }

  .icon {
    @include applyColumnWidth('right', 1, $mobile);
  }
}
