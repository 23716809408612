@use 'sass:math';
@import 'styles/theme';

.AddressBook {
  position: relative;
  display: flex;
  flex-direction: column;
  @include applyColumnWidth('width', 22, $mobile);

  @include from('md') {
    @include applyColumnWidth('width', 15, $desktop);
  }

  @include from('xxl') {
    @include applyColumnWidth('width', 10, $desktop);
  }

  .noAddresses {
    .noAddressesText {
      margin-top: theme($spacings, 30);
      text-align: center;
    }

    @include from('md') {
      .noAddressesText {
        margin-top: 0;
        text-align: left;
      }
    }
  }

  .button {
    width: 100%;

    @include from('md') {
      width: auto;
    }
  }

  .add {
    margin-top: theme($spacings, 20);
  }
}

.AddressCard {
  border: solid 1px theme($colors, 'alto');
  padding: theme($spacings, 20) theme($spacings, 15);
  box-sizing: border-box;
  @include applyColumnWidth('width', 22, $mobile);

  + .AddressCard {
    margin-top: theme($spacings, 10);
  }

  @include from('md') {
    @include applyColumnWidth('width', 15, $desktop);
    padding: theme($spacings, 25);
    display: flex;

    .textWrapper {
      width: percentage(math.div(425, 600));
      padding-right: theme($spacings, 15);
      box-sizing: border-box;
    }
  }

  @include from('xxl') {
    @include applyColumnWidth('width', 10, $desktop);
  }

  .addressName {
    margin-bottom: theme($spacings, 25);
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: theme($spacings, 20);

    @include from('md') {
      width: percentage(math.div(175, 600));
      margin-top: 0;
    }

    .button {
      width: calc(50% - 5px);

      @include from('md') {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: theme($spacings, 10);
        }
      }

      &.edit {
        border: 1px solid theme($colors, 'alto');

        @media (hover: hover) {
          &:hover {
            border-color: theme($colors, 'black');
          }
        }
      }
    }
  }
}

.popin {
  height: vh(100);
  width: vw(100);
  display: flex;
  overflow: auto !important;
  @include customScrollBarTheme();

  @include from('md') {
    height: auto;
    width: auto;
  }

  .closeIcon {
    width: 12px;

    @include from('md') {
      width: 16px;
      margin-right: -#{theme($spacings, 10)};
      margin-top: -#{theme($spacings, 10)};
      @include applyColumnWidth('top', 1, $desktop);
      @include applyColumnWidth('right', 1, $desktop);
    }
  }

  .addressForm {
    height: 100%;
    box-sizing: border-box;
    padding-top: theme($spacings, 25);
    padding-bottom: theme($spacings, 25);

    @include applyColumns(
      (
        'propertyName': 'width',
        'desktop': 10,
        'mobile': 24,
      )
    );

    @include applyColumns(
      (
        'propertyName': 'margin-left',
        'desktop': 1,
        'mobile': 1,
      )
    );

    @include applyColumns(
      (
        'propertyName': 'margin-right',
        'desktop': 1,
        'mobile': 1,
      )
    );

    @include from('md') {
      padding: 0;
      @include applyColumnWidth('margin-top', 1, $desktop);
      @include applyColumnWidth('margin-bottom', 1, $desktop);
    }
  }
}
