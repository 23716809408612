@use 'sass:math';
@import 'styles/theme';

.HeroSmall {
  @include switchDesktopMobileClasses('md');

  position: relative;

  .image {
    grid-column: 1 / -1;
  }

  .content {
    margin-top: theme($spacings, 20);

    @include applyColumns(
      (
        'propertyName': 'width',
        'desktop': 9,
        'mobile': 22,
      )
    );

    @include applyColumnWidth('margin-left', 1, $mobile);
    @include applyColumnWidth('margin-right', 1, $mobile);

    @include from('md') {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: 0;
      margin-bottom: theme($spacings, 20);
      @include applyColumnWidth('margin-left', 1, $desktop);
      @include applyColumnWidth('margin-right', 1, $desktop);
    }
  }

  .ratio {
    @include applyPercentRatios(
      math.div(360, 175),
      (
        'md': math.div(1440, 375),
      )
    );
  }

  .children {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  &.isAccount {
    .content {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-bottom: theme($spacings, 20);

      @include from('md') {
        width: calc(100% - #{getGridColumnWidth(2, $desktop)});
        margin-bottom: theme($spacings, 40);
      }
    }

    .children {
      position: relative;
      bottom: auto;
      right: auto;
      margin-left: auto;
      margin-top: auto;
      padding-left: theme($spacings, 10);
    }
  }
}
