@import 'styles/theme';

.PanelButtons {
  display: flex;
  justify-content: space-between;
  margin-top: theme($spacings, 20);

  @include from('md') {
    margin-top: 0;
    padding-left: theme($spacings, 15);
  }

  .button {
    flex: 1;

    @include from('md') {
      flex: auto;
    }

    &:first-child {
      border: solid 1px theme($colors, 'alto');
    }

    &:not(:last-child) {
      margin-right: theme($spacings, 5);
    }

    &:not(:first-child) {
      margin-left: theme($spacings, 5);
    }
  }
}
