@import 'styles/theme';

.Input {
  position: relative;

  &.hidden {
    display: none;
  }
}

.input {
  width: 100%;
  border-radius: 0;
  box-sizing: border-box;
  padding: theme($spacings, 10);
  border: 1px solid theme($colors, 'black');
  background-color: transparent;
  background-clip: padding-box;
  transition: border 0.2s ease;
}

.textarea {
  width: 100%;
  outline: none;
  border: solid 1px theme($colors, 'black');
  padding: theme($spacings, 10);
  box-sizing: border-box;
  resize: none;
  transition: border 0.2s ease;
}
