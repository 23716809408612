@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.label {
  /**
  * HAFFER REBRAND
  */
  &-10-haffer {
    @include font-base(10, null, 'Haffer', 1);
  }

  &-10-haffer-semibold {
    @include font-base(10, null, 'Haffer', 1, 'semibold');
  }

  &-10-12-haffer {
    @include font-base(10, 12, 'Haffer', 1);
  }

  &-10-12-haffer-semibold {
    @include font-base(10, 12, 'Haffer', 1, 'semibold');
  }

  &-10-14-haffer {
    @include font-base(10, 14, 'Haffer', 1);
  }

  &-12-haffer {
    @include font-base(12, null, 'Haffer', 1.5);
  }

  &-12-haffer-semibold {
    @include font-base(12, null, 'Haffer', 1, 'semibold');
  }

  &-12-13-haffer {
    @include font-base(12, 13, 'Haffer', 1.5);
  }

  &-12-14-haffer {
    @include font-base(12, 14, 'Haffer', 1.5);
  }

  &-12-14-haffer-semibold {
    @include font-base(12, 14, 'Haffer', 1.5, 'semibold');
  }

  &-14-16-haffer {
    @include font-base(14, 16, 'Haffer', 1);
  }
}
