@import 'styles/theme';

.BannerMarket {
  background: theme($colors, 'white');
  z-index: 30;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .container {
    position: relative;
    padding-top: theme($spacings, 12);
    padding-bottom: theme($spacings, 12);

    @include from('md') {
      align-items: center;
      padding-top: theme($spacings, 14);
      padding-bottom: theme($spacings, 14);
    }
  }

  .left {
    grid-column: 2/-3;
    grid-row: 1;
    display: flex;
    align-items: center;

    @include from('md') {
      grid-column: 2/10;
    }
  }

  .right {
    display: flex;
    flex-flow: row wrap;
    grid-column: 2/-2;
    grid-row: 2;
    margin-top: theme($spacings, 10);

    @include from('md') {
      justify-items: stretch;
      flex-direction: row;
    }

    @include from('lg') {
      grid-row: 1;
      margin-top: 0;
      grid-column: 11/-2;
    }

    @include from('xxl') {
      grid-column: 12/-2;
    }

    @include from('max-grid-width') {
      grid-column: 15/-2;
    }

    .selectItem {
      width: calc(50% - 2px);

      & + .selectItem {
        margin-left: theme($spacings, 4);
      }

      &.hasNoValues {
        opacity: 0.7;
        pointer-events: none;
      }

      @include from('md') {
        flex: 1;
        width: auto;

        & + .selectItem {
          margin-left: theme($spacings, 10);
        }
      }
    }

    .cta {
      width: 100%;
      margin-top: theme($spacings, 4);

      @include from('md') {
        width: auto;
        margin-left: theme($spacings, 10);
        margin-top: 0;
        flex: 0;
      }
    }
  }

  .close {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    grid-column: -3/-2;
    padding: theme($spacings, 10);
    margin: -#{theme($spacings, 10)};
    box-sizing: border-box;

    .icon {
      width: theme($spacings, 8);
      height: theme($spacings, 8);
    }

    @include from('lg') {
      margin-left: 0;
      margin-right: 0;
      height: 100%;
      grid-column: -2/-1;
      justify-content: center;
    }
  }
}
