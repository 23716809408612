@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.title {
  /**
  * HAFFER SQ REBRAND
  */
  &-10-haffer-semibold {
    @include font-base(10, null, 'Haffer', 1, 'semibold');
  }

  &-14-haffer {
    @include font-base(14, null, 'Haffer', 1);
  }

  &-10-14-haffer-semibold {
    @include font-base(10, 14, 'Haffer', 1, 'semibold');
  }

  &-12-haffer-semibold {
    @include font-base(12, null, 'Haffer', 1, 'semibold');
  }

  &-12-13-haffer-semibold {
    @include font-base(12, 13, 'Haffer', 1, 'semibold');
  }

  &-12-14-haffer-semibold {
    @include font-base(12, 14, 'Haffer', 1, 'semibold');
  }

  &-12-14-haffer {
    @include font-base(12, 14, 'Haffer', 1);
  }

  &-14-16-haffer {
    @include font-base(14, 16, 'Haffer', 1);
  }

  &-14-haffer-semibold {
    @include font-base(14, null, 'Haffer', 1, 'semibold');
  }

  &-14-18-haffer-semibold {
    @include font-base(14, 18, 'Haffer', 1, 'semibold');
  }

  &-14-24-haffer-semibold {
    @include font-base(14, 24, 'Haffer', 1, 'semibold');
  }

  &-18-haffer {
    @include font-base(18, null, 'Haffer', 1, 'normal');
  }

  &-18-haffer-semibold {
    @include font-base(18, null, 'Haffer', 1, 'semibold');
  }

  &-18-24-haffer-semibold {
    @include font-base(18, 24, 'Haffer', 1, 'semibold');
  }

  &-18-24-haffer {
    @include font-base(18, 24, 'Haffer', 1);
  }

  &-18-35-haffer-semibold {
    @include font-base(18, 35, 'Haffer', 1, 'semibold');
  }

  &-24-haffer-semibold {
    @include font-base(24, null, 'Haffer', 1, 'semibold');
  }

  &-25-35-haffer-semibold {
    @include font-base(25, 35, 'Haffer', 1, 'semibold');
  }

  &-30-35-haffer-semibold {
    @include font-base(30, 35, 'Haffer', 1, 'semibold');
  }

  &-30-60-haffer-semibold {
    @include font-base(30, 60, 'Haffer', 1, 'semibold');
  }

  &-45-haffer-semibold {
    @include font-base(45, null, 'Haffer', 1, 'semibold');
  }

  &-45-60-haffer-semibold {
    @include font-base(45, 60, 'Haffer', 1, 'semibold');
  }

  &-80-60-haffer-semibold {
    @include font-base(80, 60, 'Haffer', 1, 'semibold');
  }

  &-fluid-30-80-haffer-semibold {
    @include font-fluid-base(30, 80, 'Haffer', 1, 'semibold');
  }

  &-fluid-45-80-haffer-semibold {
    @include font-fluid-base(45, 80, 'Haffer', 1, 'semibold');
  }

  &-fluid-50-140-haffer-semibold {
    @include font-fluid-base(50, 140, 'Haffer', 0.8, 'semibold');
  }

  &-fluid-45-150-haffer-semibold {
    @include font-fluid-base(45, 150, 'Haffer', 0.8, 'semibold');
  }

  &-fluid-80-160-haffer-semibold {
    @include font-fluid-base(80, 160, 'Haffer', 0.8, 'semibold');
  }

  &-fluid-80-330-haffer-semibold {
    @include font-fluid-base(80, 330, 'Haffer', 0.8, 'semibold');
  }
}
