@import 'styles/theme';

.Icon {
  &.whiteTheme {
    path {
      &[fill] {
        fill: theme($colors, 'white');
      }

      &[stroke] {
        stroke: theme($colors, 'white');
      }
    }
  }
}

.BigArrowRightIcon {
  transform: rotate(180deg);
}

.ArrowRightIcon {
  transform: translate3d(0, -2%, 0) rotate(180deg);
}

.StoreLocator {
  width: 10px;
}

.MobileStoreLocator {
  width: 10px;
}

.CartIcon {
  width: 14px;
}

.AccountIcon {
  width: 14px;
}

.StarIcon {
  width: 10px;
  height: 8px;
}

.ArrowRightVenusIcon {
  width: 10px;
}

.BagIcon {
  width: 14px;
}

.MobileMenuIcon {
  width: 14px;
}

.VenusScrollArrow {
  width: 11px;
  height: 16px;
}
