@import 'styles/theme';

.DashboardNavigationAccordion {
  @include switchDesktopMobileClasses('md');

  @include from('md') {
    margin-top: theme($spacings, 100);
    margin-bottom: theme($spacings, 100);
  }

  .navigation {
    grid-column: 1 / -1;
    border-bottom: solid 1px theme($colors, 'gallery');
    padding-top: theme($spacings, 10);
    padding-bottom: theme($spacings, 10);

    @include from('md') {
      grid-column: 2 / 6;
      border-bottom: none;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: theme($spacings, 5);
    }
  }
}
