@import 'styles/theme';

.background {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(theme($colors, 'white'), 0.6);
}

.panelContainer {
  position: fixed;
  z-index: 11;
  width: 100%;

  @include from('lg') {
    z-index: 3;
    position: absolute;
  }
}

.mobilePanel {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: stableVh(100);
  background: theme($colors, 'white');
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close {
    width: 18px;
    height: 18px;
  }

  .content {
    grid-column: 2 / -2;
  }

  .topContainer {
    border-bottom: 1px solid theme($colors, 'gallery');
  }

  .topContainer,
  .bottomContainer {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    padding: theme($spacings, 20) 0;

    .name {
      text-align: center;
    }
  }

  .centerContainer {
    overflow: auto;
    height: 100%;
    @include customScrollBarTheme();
  }
}

.backgroundPanel {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center top;
  background: theme($colors, 'white');
  box-shadow: 0px 10px 15px 3px rgba(theme($colors, 'black'), 0.05);
}

.panel {
  z-index: 3;
  position: relative;
  background: theme($colors, 'white');

  @include from('lg') {
    background: unset;
  }
}

.Filters {
  z-index: 2;
  position: relative;
  background: theme($colors, 'white');

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .filterItem {
      display: flex;
      align-items: center;
      flex-direction: row;
      opacity: 0.5;
      transition: opacity 0.2s;

      &.isActive {
        opacity: 1;
      }

      &:not(:last-child) {
        margin-right: theme($spacings, 20);

        @include from('md') {
          margin-right: theme($spacings, 40);
        }
      }

      .arrowBottom {
        width: 6px;
        fill: theme($colors, 'black');
        margin-left: theme($spacings, 10);
        transition: transform 0.3s theme($easings, 'ease-in-out');

        &.isActive {
          transform: rotateZ(-180deg);
        }
      }
    }
  }

  .content {
    grid-column: 2 / -2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: theme($spacings, 25) 0;
  }
}
