@import 'styles/theme';

// Tracking portal
#parcellab-track-and-trace-ui-wrapper {
  font-family: theme($fontFamilies, 'Haffer') !important;

  .pl-box-heading {
    font-weight: theme($fontWeights, 'semibold') !important;
    text-transform: uppercase;
  }

  #pl-show-more-button {
    text-transform: uppercase;
    font-family: theme($fontFamilies, 'Haffer') !important;
    font-size: theme($fontSizes, 12) !important;

    @include from('md') {
      font-size: theme($fontSizes, 14) !important;
    }
  }
}

// Returns portal
#pl-returns-plugin {
  font-family: theme($fontFamilies, 'Haffer') !important;

  // Overriding colors
  --color-primary: #{theme($colors, 'black')} !important;
  --font-size: #{theme($fontSizes, 12)} !important;
  --color-lightGrey: #{theme($colors, 'alto')} !important;
  --color-grey: #{theme($colors, 'alto')} !important;

  h1,
  h2,
  h3 {
    font-weight: theme($fontWeights, 'semibold') !important;
    text-transform: uppercase;
  }

  h1 {
    font-size: theme($fontSizes, 18);

    @include from('md') {
      font-size: theme($fontSizes, 24);
    }
  }

  h3 {
  }

  .pl-input {
    padding: theme($spacings, 15) theme($spacings, 10) !important;
    color: theme($colors, 'black') !important;
  }

  .pl-button {
    text-transform: uppercase;
    font-family: theme($fontFamilies, 'Haffer') !important;
    font-size: theme($fontSizes, 12) !important;

    @include from('md') {
      font-size: theme($fontSizes, 14) !important;
    }
  }

  .pl-card {
    box-shadow: none !important;
  }

  .pl-card-article {
    .pl-article-desc-list {
      .pl-desc-item {
        font-weight: theme($fontWeights, 'semibold') !important;
        text-transform: uppercase;

        .pl-desc-text {
          font-weight: theme($fontWeights, 'regular') !important;
        }

        &.pl-item-name {
          .pl-desc-text {
            font-weight: theme($fontWeights, 'semibold') !important;
          }
        }
      }
    }
  }

  .pl-checkbox {
    .pl-checkbox-icon {
      border-radius: 0px !important;
    }
  }

  label:not(.pl-radio):not(.pl-checkbox) {
    font-size: theme($fontSizes, 12) !important;
    text-transform: uppercase;
    font-weight: theme($fontWeights, 'regular') !important;
    margin-bottom: 4px;
    display: inline-flex;
  }
}
