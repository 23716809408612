@import 'styles/theme';

@mixin font-regular {
  font-weight: theme($fontWeights, 'regular');
}

@mixin font-medium {
  font-weight: theme($fontWeights, 'medium');
}

@mixin font-base(
  $mobileSize,
  $desktopSize,
  $family,
  $lineHeight,
  $weight: 'normal',
  $style: null
) {
  font-family: theme($fontFamilies, $family);
  font-size: theme($fontSizes, $mobileSize);
  font-weight: theme($fontWeights, $weight);
  line-height: $lineHeight;

  @if $desktopSize != null {
    @include from('md') {
      font-size: theme($fontSizes, $desktopSize);
    }
  }

  @if $style != null {
    font-style: $style;
  }
}

@mixin font-fluid-base(
  $mobileSize,
  $desktopSize,
  $family,
  $lineHeight,
  $weight: 'normal',
  $style: null
) {
  font-family: theme($fontFamilies, $family);
  font-weight: theme($fontWeights, $weight);
  line-height: $lineHeight;

  @if $style != null {
    font-style: $style;
  }

  @include fluidFontSizeType(
    theme($screens, 'md'),
    theme($max, 'width-design'),
    theme($fluidFontSizes, $mobileSize),
    theme($fluidFontSizes, $desktopSize)
  );
}
