@import 'styles/theme';

.PanelHeader {
  width: calc(100% - #{getGridColumnWidth(2, $mobile)});
  margin-top: theme($spacings, 20);
  margin-bottom: theme($spacings, 20);
  display: flex;
  flex-direction: column;

  @include applyColumnWidth('margin-left', 1, $mobile);
  @include applyColumnWidth('margin-right', 1, $mobile);

  .arrowBottom {
    width: 5px;
    margin-left: theme($spacings, 10);
    transition: transform 0.3s theme($easings, 'ease-in-out');

    &.isActive {
      transform: rotateZ(-90deg);
    }
  }

  p {
    display: flex;
    justify-content: space-between;
  }

  .orderToggler {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    color: theme($colors, 'black');
  }

  @include from('md') {
    justify-content: space-between;
    flex-direction: row;
    margin: theme($spacings, 20) theme($spacings, 25);
    width: calc(100% - #{theme($spacings, 50)});

    p {
      flex: 2;
      text-align: left;
      white-space: nowrap;

      &:first-child {
        flex: 3;
      }

      &:last-child {
        text-align: right;

        span {
          width: 100%;
        }
      }

      &:not(:last-child) {
        margin-right: theme($spacings, 10);

        span {
          &:first-child {
            display: none;
          }
        }
      }

      + p {
        margin-top: 0;
      }
    }
  }
}
