@import 'styles/theme';

.PanelItem {
  display: flex;
  align-items: center;

  @include from('md') {
    width: 48%;
  }

  .image {
    width: 60px;
    margin-right: theme($spacings, 15);
  }

  .ratio {
    @include applyPercentRatios(1);
  }

  .content {
    flex: 1;

    p {
      + p {
        margin-top: theme($spacings, 4);
      }
    }

    .meta {
      display: inline-flex;
      flex-flow: row wrap;

      .metaWrapper {
        display: flex;
        margin-right: theme($spacings, 10);

        .metaValue {
          color: theme($colors, 'black');
          margin-left: theme($spacings, 3);
        }
      }

      span:not(.metaWrapper) {
        display: block;
        white-space: nowrap;

        &:not(:last-child) {
          margin-right: theme($spacings, 15);
        }

        @include from('md') {
          display: inline;
        }
      }
    }
  }

  .price {
    margin-left: auto;
    padding-left: theme($spacings, 15);

    @include from('md') {
      margin-left: 0;
    }
  }
}
