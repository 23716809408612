@import 'styles/theme';

.panel {
  margin: 0 auto;
  border-top: 1px solid theme($colors, 'gallery');
  padding-bottom: theme($spacings, 20);
  max-width: theme($max, 'width');
  box-sizing: border-box;

  @include from('lg') {
    @include applyColumnWidth('padding-left', 1, $desktop);
    @include applyColumnWidth('padding-right', 1, $desktop);
  }

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:last-child {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.button {
  grid-column: 1/-1 !important;

  @include from('lg') {
    grid-row: 1;
  }
}

.clearButton {
  @include from('lg') {
    grid-column: 1/2 !important;
    justify-self: flex-start;
  }
}

.submitButton {
  margin-top: theme($spacings, 15);

  @include from('lg') {
    grid-column: 2/3 !important;
    justify-self: center;
  }
}
