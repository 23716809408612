@import 'styles/theme';

.RecaptchaHandler {
  position: relative;

  .marker {
    visibility: hidden;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 1px;
  }
}
