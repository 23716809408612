@import 'styles/theme';

.column {
  width: 100%;
  margin-top: theme($spacings, 30);

  @include from('lg') {
    @include applyColumnWidth('width', 4, $desktop);

    &:not(:last-child) {
      @include applyColumnWidth('margin-right', 1, $desktop);
    }
  }

  .columnTitle {
    margin-bottom: theme($spacings, 20);
  }

  .columnFilters {
    height: 100%;
    max-height: 100px;

    &.scrollable {
      overflow-y: scroll;
    }

    .columnFilter {
      &:not(:last-child) {
        margin-bottom: theme($spacings, 10);
      }
    }
  }
}

.panelHead {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: theme($spacings, 20) 0;
  border-bottom: 1px solid theme($colors, 'gallery');

  @include from('md') {
    padding: theme($spacings, 20) 0;
  }

  .icon {
    width: 10px;
  }
}

.panelBody {
  padding-top: theme($spacings, 20);
  padding-bottom: theme($spacings, 20);
}

.accordionPanel {
  width: 100%;

  &:last-child {
    .panelHead {
      border-bottom: 1px solid theme($colors, 'gallery');
    }
  }
}
