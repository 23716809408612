@import 'styles/theme';

.rich-text {
  // Spacings
  p {
    & + ul {
      margin-top: theme($spacings, 5);
    }
  }

  ul {
    & + p {
      margin-top: theme($spacings, 5);
    }
  }

  // Styles
  ul {
    list-style: inside;
  }
}
