@import 'styles/theme';

.input {
  /**
  * HAFFER SQ REBRAND
  */
  &-12-haffer {
    @include font-base(12, null, 'Haffer', 1);
  }
  &-12-14-haffer {
    @include font-base(12, 14, 'Haffer', 1);
  }

  &-14-16-haffer {
    @include font-base(14, 16, 'Haffer', 1);
  }
}
