@import 'styles/theme';

.Image {
  position: relative;
  width: 100%;
  z-index: 0;
}

.placeholder {
  &.light {
    background-color: theme($colors, 'alabaster');
  }

  &.dark {
    background-color: theme($colors, 'black');
  }
}
