@import 'styles/theme';

.LinkListNavigation {
  .links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: theme($spacings, 30);

    .link {
      text-align: left;

      + .link {
        margin-top: theme($spacings, 6);
      }
    }

    + .footer {
      padding-top: theme($spacings, 30);
      border-top: 1px solid theme($colors, 'alto');
    }
  }

  .footer {
    .headline {
      + .text {
        margin-top: theme($spacings, 20);
      }
    }

    .text {
      + .links {
        margin-top: theme($spacings, 20);
      }
    }

    .links {
      .link {
        + .link {
          margin-top: theme($spacings, 5);
        }
      }
    }
  }
}
