@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.text {
  /**
  * HAFFER SQ REBRAND
  */
  &-12-haffer {
    @include font-base(12, 12, 'Haffer', 1);
  }
  &-14-haffer {
    @include font-base(14, 14, 'Haffer', 1);
  }
  &-12-14-haffer {
    @include font-base(12, 14, 'Haffer', 1.5);
  }
  &-14-16-haffer {
    @include font-base(14, 16, 'Haffer', 1);
  }

  //100% interlignage
  &-12-14-1-haffer {
    @include font-base(12, 14, 'Haffer', 1);
  }
}
