@import 'styles/theme';

.Page {
  position: relative;

  &:not(.isHeroTitleOnly) {
    &.withoutMarginTop {
      > *:first-child {
        padding-top: 0;
      }
    }
  }
  > *:last-child {
    margin-bottom: 0;
  }

  > *:first-child {
    margin-top: 0;
    padding-top: #{theme($navHeight, 'mobile') + theme($spacings, 50)};

    @include from('md') {
      padding-top: #{theme($navHeight, 'desktop') + theme($spacings, 60)};
    }
  }

  &.pageHasNoMarginTop {
    > *:first-child {
      padding-top: theme($navHeight, 'mobile');

      @include from('md') {
        padding-top: 0;
      }
    }
  }

  &.venusPage {
    > *:first-child {
      padding-top: 0;
    }
  }
}
