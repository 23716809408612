@import 'styles/theme';

.Breadcrumb {
  display: block;
  position: relative;

  @include from('xxs') {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    align-items: center;
  }

  .separator {
    margin: 0 theme($spacings, 3);
  }
}
