@import 'styles/theme';

.FooterNewsletter {
  .headline {
    margin-bottom: theme($spacings, 10);

    + .text {
      margin-top: theme($spacings, 10);
    }
  }

  .text {
    margin-bottom: theme($spacings, 16);

    @include from('md') {
      margin-bottom: theme($spacings, 20);
    }
  }
}
