@import 'styles/theme';

.AccountInformation {
  form {
    > div {
      grid-column: span 1;

      @include from('md') {
        &:nth-child(3) {
          grid-column: span 2;
        }
      }
    }

    @include from('md') {
      & > fieldset {
        margin-top: theme($spacings, 4);
      }
    }
  }

  .profile {
    margin-bottom: theme($spacings, 50);
  }

  .title {
    border-top: 1px solid theme($colors, 'alto');
    padding-top: theme($spacings, 30);

    @include from('md') {
      padding-top: theme($spacings, 50);
    }
  }

  .password {
    margin-top: theme($spacings, 30);

    @include from('md') {
      margin-top: theme($spacings, 50);
    }
  }

  .form {
    position: relative;

    .loading {
      position: absolute;
      top: calc(50% - #{theme($spacings, 10)});
      left: calc(50% - #{theme($spacings, 10)});
      width: theme($spacings, 20);
    }

    &.isLoading {
      form {
        opacity: 0.3;
        pointer-events: none;
        cursor: wait;
      }
    }
  }
}
